import React from "react"
import LayoutCourse from "../../../../../layouts/course"
import Seo from "../../../../../components/seo"
import Heading from "../../../../../components/heading"
import Stack from "../../../../../components/stack"
import InlineHelp from "../../../../../components/inline-help"
import CourseFooterNext from "../../../../../components/course-footer-next"
import {
  getCourseNavigation,
  useCourseStore,
} from "../../../../../store/courses"
import TaskSidebar from "../../../../../components/task-sidebar"
import Note from "../../../../../components/note"
import NotationGroup from "../../../../../components/notation-group"
import SubstantiveAdjektiveNotation from "../../../../../content/kurse/haelfte-des-lebens/01-zwei-haelften/substantive-adjektive-notation"
import Paragraph from "../../../../../components/paragraph"

const Page = () => {
  const { setAnswer } = useCourseStore()
  const navigation = getCourseNavigation({ courseId: "haelfte-des-lebens" })

  return (
    <LayoutCourse
      as="form"
      navigation={navigation}
      footer={
        <CourseFooterNext to="/kurse/haelfte-des-lebens/01-zwei-haelften/substantive-und-adjektive/loesung" />
      }
    >
      <Seo title="Substantive und Adjektive" />
      <Stack>
        <Heading as="h2" level={2}>
          Substantive und Adjektive
        </Heading>
        <Paragraph>
          Die Versgrenzen, der Satzbau und der sich daraus ergebende Rhythmus
          sorgen somit dafür, dass zwischen den beiden Strophen ein Bruch
          spürbar wird. Das Gedicht zerfällt in zwei Hälften. Doch wie verhalten
          sich diese beiden Hälften inhaltlich zueinander?
        </Paragraph>
        <TaskSidebar
          main={
            <NotationGroup
              onNotationsUpdated={(notations) => {
                setAnswer({
                  courseId: "haelfte-des-lebens",
                  chapterId: "01-zwei-haelften",
                  taskId: "substantive-und-adjektive",
                  answer: notations,
                })
              }}
            >
              <SubstantiveAdjektiveNotation />
            </NotationGroup>
          }
          sidebar={
            <Stack space={6}>
              <Note variant="task">
                Markiere alle Substantive und Adjektive des Gedichts, indem du
                sie anklickst. Klicke erneut auf ein Wort, um die Markierung
                wieder zu entfernen. Fällt dir dabei etwas auf?
              </Note>
              <InlineHelp title="Was ist nochmal ein Substantiv?">
                Substantive sind sogenannte Hauptwörter. Sie haben einen Artikel
                (der, die, das) und werden in der Regel groß geschrieben.
              </InlineHelp>
              <InlineHelp title="Was ist nochmal ein Adjektiv?">
                Adjektive sind sogenannte Eigenschaftswörter. Sie beschreiben,
                wie jemand oder etwas ist.
              </InlineHelp>
            </Stack>
          }
        />
      </Stack>
    </LayoutCourse>
  )
}

export default Page
